import { reactive, toRefs } from 'vue';
import { toastController } from '@ionic/vue';

export enum ToastType {
  Success = 'primary',
  Error = 'danger',
  WARNING = 'warning',
}

export type ToastPosition = 'top' | 'bottom' | 'middle';

export function useToast(defaultDuration = 1500) {
  const state = reactive({
    toast: null,
    duration: defaultDuration,
  });

  const showMsgToast = async (
    type: ToastType = ToastType.Success,
    message: string,
    duration = state.duration,
    position: ToastPosition = 'top',
  ) => {
    state.toast = await toastController.create({
      message,
      duration,
      color: type,
      position,
    });
    await state.toast.present();
  };

  return {
    ...toRefs(state),
    showMsgToast,
  };
}
