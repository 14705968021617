import axios, { AxiosResponse } from 'axios';
import md5 from 'react-native-md5';
import base64 from 'react-native-base64';
import utf8 from 'utf8';
import { useUserStore } from '@/store/modules/user';
import { ContentTypeEnum } from '@/enums/httpEnum';
import { BaseResponseModel } from '@/api';
import { ToastType, useToast } from '@/hooks/useToast';
import { AxiosRequestHeaders } from 'axios/index';

// interface GraphQLResponse<T = any> {
//   data?: T;
//   message?: string;
//   status?: number;
// }
const { showMsgToast } = useToast();
const url = process.env.VUE_APP_BASE_API;
// 创建 axios 实例
const instance = axios.create({
  baseURL: url,
  withCredentials: false,
});

// 添加请求拦截器
// instance.interceptors.request.use(
//   (config) => {
//     // 在发送请求之前做些什么
//     config.headers = authSignHeader(config.data.query, config.data.variables) as AxiosRequestHeaders;
//     return config;
//   },
//   (error) => {
//     // 对请求错误做些什么
//     return Promise.reject(error);
//   },
// );

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    if (response.data.status !== 0) {
      showMsgToast(ToastType.Error, response.data.message).then((r_) => {});
    }
    return response;
  },
  (error) => {
    // 对响应错误做点什么
    if (error.response && error.response.status) {
      let errorText = '';
      switch (error.response.status) {
        case 401:
          errorText = 'Unauthorized';
          break;
        case 403:
          errorText = 'Access Denied';
          break;
        case 500:
          errorText = 'Server Error';
          break;
        default:
          errorText = 'Network Error';
      }
      showMsgToast(ToastType.Error, errorText).then((r_) => {});
    }
    return Promise.reject(error);
  },
);

const authSignHeader = (query: string, variables?: Record<string, any>) => {
  let token = useUserStore().token;
  const isLogin = useUserStore().isLogin;
  const time = new Date().getTime() + '';
  if (!isLogin) {
    token = '';
  }

  // token+query+variables+timestamp
  const str = base64.encode(token + query + utf8.encode(JSON.stringify(variables)) + time);
  // 签名
  const sign = md5.hex_md5(str).toLowerCase();
  // 生成校验字符串
  let auth = base64.encode(sign + ';' + time + ';' + token);
  if (!isLogin) {
    auth = base64.encode(sign + ';' + time);
  }
  // 添加Authorization报头
  return {
    'Content-Type': ContentTypeEnum.JSON,
    Authorization: auth,
  };
};

export async function request<T = any>(query: string, variables?: Record<string, any>): Promise<BaseResponseModel<T>> {
  const response: AxiosResponse<BaseResponseModel<T>> = await instance.post(
    url,
    {
      query: query,
      variables: JSON.stringify(variables),
    },
    {
      headers: authSignHeader(query, variables),
      withCredentials: false,
    },
  );

  return response.data;
}
