import { CountryVO, CurrencyVO, ImageVO } from '@/api/graphqls-vo/BasicVo';

export const LocationVo = `
  location_id,
  location_type,
  sn,
  company{
      company_id,
      company_name,
      company_address,
      company_type,
      status,
      contact_email,
      contact_phone,
      city,
      province,
      zip,
      country{${CountryVO}},
      currency{${CurrencyVO}}
  },

  main_company_id,
  username,
  name,
  logo{${ImageVO}},
  background{${ImageVO}},
  thumbs{${ImageVO}},
  address,
  country{${CountryVO}},
  currency{${CurrencyVO}},
  region,
  city,
  store_phone,
  status,
  latitude,
  longitude,
  distance
`;

export const CategoryVO = `
    category_id,
    name,
    company_id,
`;
