import { MemberVo } from '@/api/graphqls-vo/MemberVo';

export const DiscountContentVo = `
id,
name,
type,
amount_over,
discount,
`;

export const VoucherVo = `
voucher_id,
company_id,
name,
description,
type,
discount_rule,
discount_content{${DiscountContentVo}},
reward_points,
expiration_date_range,
timelength,
date_rule,
status,
thumb,
stackable,
priority,
created_at,
`;

export const MemberVoucherVo = `
member_voucher_id,
member_voucher_no,
company_id,
member_uid,
member{
  uid,
  company_id,
  mobile,
  reward_point,
  transaction_date,
  status,
  name,
  avatar,
  email,
  wechat,
  gender,
  phone,
  idcard,
  address,
  age,
  birthday,
  billing_address,
},
voucher_id,
voucher_type,
voucher{${VoucherVo}},
expiry_start,
expiry_end,
created_at,
`;
