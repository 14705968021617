export const BaseReturnMessage = `
    status,
    message,
`;
export const CountryVO = `
        country_id,
        name,
        i18n,
`;
export const CurrencyVO = `
        currency_id,
      name,
      symbol,
      i18n,
`;

export const ImageVO = `
image_id, name, url,
url_size, url_avatar, url_small,
url_small_size, url_large, url_large_size,
created_at
`;

export const PagerVo = `
offset,
size,
total,
`;

export const StaffVo = `
    staff_id,
    staff_no,
    company_id,
    staff_type,
    username,
    nickname`;

export const CustomFieldValueVo = `name, value`;
