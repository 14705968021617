import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
import { App } from 'vue';
import { createRouterGuards } from '@/router/router-guards';
import HomeRules from '@/router/modules/home-rules';
import ShopRules from '@/router/modules/shop-rules';
import OrderRoute from '@/router/modules/order-rules';
import AccountRules from '@/router/modules/account-rules';
export const RegisterRoute: RouteRecordRaw = {
  path: '/register',
  name: 'Register',
  component: () => import('@/views/login/RegisterPage.vue'),
};
export const LoginRoute: RouteRecordRaw = {
  path: '/login',
  name: 'Login',
  component: () => import('@/views/login/LoginPage.vue'),
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/home',
      },
      ...HomeRules,
      ...ShopRules,
      ...OrderRoute,
      ...AccountRules,
    ],
  },
  LoginRoute,
  RegisterRoute,
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export function setupRouter(app: App) {
  app.use(router);
  router.isReady().then(() => {
    app.mount('#app');
  });
  // 创建路由守卫
  createRouterGuards(router);
}

// export default router;
