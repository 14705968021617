import { defineStore } from 'pinia';
import { createStorage } from '@/utils/Storage';
import { store } from '@/store';
import { ACCESS_TOKEN, CURRENT_USER } from '@/store/mutation-types';
import { Member } from '@/types/member/member';
import { ILoginFrom } from '@/views/login/hooks';
import { ILoginDataType, memberLogin, memberRegister } from '@/api/member-api';
import { awaitWrap } from '@/utils';
import { getMember, getToken, removeToken, setMember, setToken } from '@/utils/auth';
const Storage = createStorage({ storage: localStorage });

export interface IUserState {
  token: string;
  member: Member;
}
export const useUserStore = defineStore({
  id: 'app-user',
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'user',
        storage: localStorage,
      },
    ],
  },
  state: (): IUserState => ({
    token: Storage.get(ACCESS_TOKEN, ''),
    member: Storage.get(CURRENT_USER, null),
  }),
  getters: {
    getToken(): string {
      return this.token || getToken();
    },
    getMemberInfo(): object {
      return this.member || getMember();
    },
    isLogin(): boolean {
      return !!this.token || !!getToken();
    },
  },
  actions: {
    setToken(token: string) {
      this.token = token;
      Storage.set(ACCESS_TOKEN, token);
    },
    setMember(member: Member) {
      this.member = member;
      Storage.set(CURRENT_USER, member);
    },
    // 用户登录
    async login(loginForm: ILoginFrom) {
      try {
        const [res, err] = await awaitWrap<ILoginDataType>(memberLogin(loginForm));
        if (!err) {
          const { data, status, message } = res;
          if (status === 0) {
            setToken(data.token);
            this.setToken(data.token);

            setMember(data.member);
            this.setMember(data.member);
            return Promise.resolve(data);
          } else {
            return Promise.reject(res);
          }
        } else {
          return Promise.reject(err);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },

    // 登出
    async logout() {
      removeToken();
      this.setToken(null);
      this.setMember(null);
      Storage.remove(ACCESS_TOKEN);
      Storage.remove(CURRENT_USER);
      return Promise.resolve('');
    },
    // 注册
    async register(form: ILoginFrom) {
      try {
        const [res, err] = await awaitWrap<ILoginDataType>(memberRegister(form));
        if (err) {
          return Promise.reject(err);
        }
        const { data, status } = res;
        if (status === 0) {
          setToken(data.token);
          this.setToken(data.token);

          setMember(data.member);
          this.setMember(data.member);
          return Promise.resolve(data);
        }
        return Promise.reject(res);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
});

// 非setup
export function useUserStoreHook() {
  return useUserStore(store);
}
