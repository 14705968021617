import { RouteLocationNormalized, Router } from 'vue-router';
import { useUserStoreHook } from '@/store/modules/user';
import { useLoginModal } from '@/hooks/useModal';
import { useTabsViewStoreHook } from '@/store/modules/tabsView';
const { openLoginModal } = useLoginModal();
function checkNeedLoginPath(to: RouteLocationNormalized): boolean {
  // 订单
  const names = ['Order', 'Checkout'];
  if (names.includes(String(to.name))) {
    return true;
  }
  // 我的 url
  return to.path.indexOf('account') > 0;
}
export function createRouterGuards(router: Router) {
  const userStore = useUserStoreHook();
  const usTabsViewStore = useTabsViewStoreHook();
  router.beforeEach((to, from, next) => {
    //如果用户未登录，要访问的页面并且需要登录才能访问
    if (!userStore.isLogin && checkNeedLoginPath(to)) {
      // 重定向到登录页面
      next({ path: 'Login', query: { t: to.path, f: from.path } });
      // openLoginModal();
      // return;
    } else if (userStore.isLogin && ['Login', 'Register'].includes(String(to.name))) {
      // 已登录再访问 登录、注册页面 跳转到首页
      usTabsViewStore.setPageName(to.name as string);
      next({ name: 'Home' });
    } else {
      usTabsViewStore.setPageName(to.name as string);
      next();
    }
  });
}

// async function openLoginModal() {
//   await openModal(LoginPage, { cssClass: 'control_modal_height' });
// }
