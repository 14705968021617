import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/OrderPage.vue'),
    meta: {
      title: 'Order',
    },
  },
];
export default routes;
