import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    name: 'Shop',
    path: 'shop',
    component: () => import('@/views/shop/ShopPage.vue'),
  },
  {
    name: 'ShopProduct',
    path: '/shop/product',
    component: () => import('@/views/shop/ShopProductPage.vue'),
  },
  {
    name: 'Checkout',
    path: 'checkout',
    component: () => import('@/views/shop/CheckoutPage.vue'),
  },
];
export default routes;
