import { modalController } from '@ionic/vue';
import LoginPage from '@/views/login/LoginPage.vue';
export function useModal() {
  const openModal = async (component: any, options: object) => {
    const modal = await modalController.create({
      component,
      ...options,
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    return { data, role };
  };

  return {
    openModal,
  };
}

export function useLoginModal() {
  const { openModal } = useModal();
  const openLoginModal = async () => {
    await openModal(LoginPage, { cssClass: 'control_modal_height' });
  };

  return {
    openLoginModal,
  };
}
