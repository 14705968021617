import { request } from '@/utils/request';
import { ILoginFrom } from '@/views/login/hooks';
import { LocationVo } from '@/api/graphqls-vo/LocationVo';
import { MemberSystemConfigVo, MemberVo } from '@/api/graphqls-vo/MemberVo';
import { BaseResponseModel } from '@/api/index';
import { MemberSession, MemberSystemConfig } from '@/types/member/member';
import { IMemberInputFrom } from '@/views/account/hooks';
import { BaseReturnMessage } from '@/api/graphqls-vo/BasicVo';

const COMPANY_ID = process.env.VUE_APP_COMPANY_ID;

export type ILoginDataType = BaseResponseModel<MemberSession>;

// 登录
export function memberLogin(params: ILoginFrom) {
  params.company_id = COMPANY_ID;
  const gql = `query($company_id:String!, $mobile:String!, $password:String!){
        mc_signin(company_id:$company_id, mobile:$mobile, password:$password){
            token,
            location_list{${LocationVo}},
            member{${MemberVo}}
        }
    }
    `;
  return request(gql, params);
}

// 注册
export function memberRegister(params: ILoginFrom) {
  params.company_id = COMPANY_ID;
  const gql = `mutation($company_id:String!, $mobile:String!, $password:String!){
        mc_signup(company_id:$company_id, mobile:$mobile, password:$password){
            token,
            location_list{${LocationVo}},
            member{${MemberVo}}
        }
    }
    `;
  return request(gql, params);
}

export function memberUpdateInfo(input: IMemberInputFrom) {
  const gql = `mutation($input:MemberInput!){
            mc_setMemberInfo(input:$input){
                ${MemberVo}
            }
        }
        `;
  return request(gql, { input });
}

export function getMemberInfo() {
  const gql = `query{
        mc_getMemberInfo{
            ${MemberVo}
        }
    }`;
  return request(gql, {});
}

export function getMemberSystemConfig() {
  const gql = `query{
    getMemberSystemConfig{
      ${MemberSystemConfigVo}
      }
    }`;
  return request<MemberSystemConfig>(gql, {});
}

//发送验证码
export function sendSms(phone: string) {
  const gql = `query($company_id:String!, $phone:String!){
        mc_findPassword(company_id:$company_id, phone:$phone){
            ${BaseReturnMessage}
        }
    }
    `;
  return request(gql, { company_id: COMPANY_ID, phone });
}

export function resetPassword(phone: string, new_password: string, code: string) {
  const gql = `mutation($company_id:String!, $phone:String!, $vcode:String!, $new_password:String!){
        mc_resetPassword(company_id:$company_id, phone:$phone, vcode:$vcode, new_password:$new_password){
            ${BaseReturnMessage}
        }
    }
    `;
  return request(gql, { company_id: COMPANY_ID, phone, vcode: code, new_password });
}
