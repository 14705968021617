import { PrepaidCardVo } from '@/api/graphqls-vo/PrepaidCardVo';
import { CustomFieldValueVo } from '@/api/graphqls-vo/BasicVo';
import { VoucherVo } from '@/api/graphqls-vo/VoucherVo';

export const MembershipLevelVo = `
  membership_level_id,
  company_id,
  level_name,
  level_shortdesc,
  level_longdesc,
  condition_amount,
  condition_date_range,
  timelength,
  voucher_list{${VoucherVo}},
  voucher_ids,
  status,
`;

export const MemberVo = `
  uid,
  company_id,
  mobile,
  membership_level{${MembershipLevelVo}},

  reward_point,
  transaction_date,
  status,

  prepaid_card{${PrepaidCardVo}},

  name,
  avatar,
  email,
  wechat,
  gender,
  phone,
  idcard,
  address,
  age,
  birthday,
  billing_address,
  created_at,

  membership,
  series_no,
  custom_field_values{${CustomFieldValueVo}},
  car_no,
  member_type,
  team_uid,
`;

export const MemberRewardPointLogVo = `
    reward_point_log_id,
    uid,
    company_id,
    type,
    points,
    points_before,
    points_after,
    description,

    sign,
    created_at,
`;

export const MemberSystemConfigVo = `
working_day{weekday, start_time, end_time},
holiday,
reward_point_enable,
gift_card_enable,
service_fee,
`;
