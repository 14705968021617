import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    name: 'Home',
    path: 'home',
    component: () => import('@/views/home/HomePage.vue'),
  },
];
export default routes;
