import Cookies from 'js-cookie';
import { Member } from '@/types/member/member';
const expires = 90;
const TokenKey = 'Member-Token';

const MemberKey = 'Member-Info';
export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token: string) {
  return Cookies.set(TokenKey, token, { expires });
}

export function getMember() {
  const member = Cookies.get(MemberKey);
  if (member) {
    return JSON.parse(member);
  }
  return null;
}

export function setMember(member: Member) {
  return Cookies.set(MemberKey, JSON.stringify(member), { expires });
}

export function removeToken() {
  Cookies.remove(MemberKey);
  return Cookies.remove(TokenKey);
}
