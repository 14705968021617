import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    name: 'Account', // 账户
    path: 'account',
    component: () => import('@/views/account/AccountPage.vue'),
  },
  {
    name: 'Voucher',
    path: 'account/voucher',
    component: () => import('@/views/account/VoucherPage.vue'),
  },
  {
    name: 'RewardPoint',
    path: 'account/reward',
    component: () => import('@/views/account/RewardPointPage.vue'),
  },
  {
    name: 'Membership',
    path: 'account/membership',
    component: () => import('@/views/account/MembershipPage.vue'),
  },
  {
    name: 'Person',
    path: 'account/personalinfo',
    component: () => import('@/views/account/PersonPage.vue'),
  },
  {
    name: 'Wallet',
    path: 'account/wallet',
    component: () => import('@/views/account/WalletPage.vue'),
  },
  {
    name: 'Gift',
    path: 'account/gift',
    component: () => import('@/views/account/GiftPage.vue'),
  },
  {
    name: 'About',
    path: 'account/about',
    component: () => import('@/views/account/AboutPage.vue'),
  },
];
export default routes;
