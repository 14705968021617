import { defineStore } from 'pinia';
import { store } from '@/store';
export const useTabsViewStore = defineStore({
  id: 'app-tabs-view',
  persist: {
    enabled: true,
  },
  state: () => ({
    pageName: 'Home',
  }),
  getters: {
    getPageName(): string {
      return this.pageName;
    },
  },
  actions: {
    setPageName(name: string) {
      this.pageName = name;
    },
  },
});

export function useTabsViewStoreHook() {
  return useTabsViewStore(store);
}
