// 预付卡面额
export const TopupValueVo = `
    topup_value_id,
    company_id,
    amount,
    credit_amount,
    description,
`;

export const PrepaidCardVo = `
prepaid_card_id,
company_id,
uid,

balance,
consumption_amount,
total_consumption_amount,
consumption_start_at,

status,
balance_verify,
expiry_date,
updated_at,
created_at,
`;
