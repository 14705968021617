import dayjs from 'dayjs';
import _ from 'lodash';
/**
 * 封装await
 * @param promise
 */
export function awaitWrap<T, U = Error>(promise: Promise<T>): Promise<[T, null] | [null, U]> {
  return promise.then<[T, null]>((res: T) => [res, null]).catch<[null, U]>((err) => [null, err]);
}

/**
 * 判断单号日期是否为今天
 * @param invoiceNo - 单号字符串，格式为 'YYYYMMDDXXXXXX'
 * @returns 是否为今天
 */
export const isInvoiceDateToday = (invoiceNo: string): boolean => {
  // 提取单号前面的年月日信息
  const dateStr = invoiceNo.substring(0, 8);

  // 格式化日期字符串为 YYYY-MM-DD
  const formattedDate = dayjs(dateStr, 'YYYYMMDD').format('YYYY-MM-DD');

  // 获取今天的日期，格式化为 YYYY-MM-DD
  const todayDate = dayjs().format('YYYY-MM-DD');

  // 判断单号日期是否为今天
  return _.isEqual(formattedDate, todayDate);
};
